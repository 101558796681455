import * as React from 'react';
import {
    AgoraVideoPlayer,
    createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import { query, where, addDoc, collection, serverTimestamp, onSnapshot, orderBy, limit, doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firestore";
import AssessorExamChat from './AssessorExamChat';

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const Videos = ({ tracks }) => {
    return (
        <div>
            <AgoraVideoPlayer videoTrack={tracks[1]} style={{ height: '100px', width: '100px' }} />
        </div>
    );
};

const CandidateProactor = ({ channelName, client, config, candidate }) => {
    const [users, setUsers] = React.useState([]);
    const { ready, tracks } = useMicrophoneAndCameraTracks();
    const [chatParentId, setChatParentId] = React.useState('');
    const [chat, setChat] = React.useState({});

    const setChatUser = React.useCallback(async (uid) => {
        const q = query(
            collection(db, "chat_users"),
            where('user', "==", candidate._id),
            where('channel', "==", channelName),
            limit(1)
        );

        onSnapshot(q, async (QuerySnapshot) => {
            if (QuerySnapshot.empty) {
                let docRef = await addDoc(collection(db, "chat_users"), {
                    user: candidate._id,
                    name: candidate.name,
                    channel: channelName,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                    uid,
                });
                let getData = await docRef.get();
                setChat({ id: getData.id, uid: uid, user: getData.data() });
            } else {
                let docRef = doc(db, "chat_users", QuerySnapshot.docs.at(0).id);
                if (QuerySnapshot.docs.at(0).data().uid !== uid) {
                    let data = { ...QuerySnapshot.docs.at(0).data(), uid: uid, updatedAt: serverTimestamp() };
                    updateDoc(docRef, data)
                        .then(docRef => {
                            console.log("A New Document Field has been added to an existing document");
                        })
                        .catch(error => {
                            console.error("update error", error);
                        })
                }
                setChat({ id: QuerySnapshot.docs.at(0).id, uid: uid, user: QuerySnapshot.docs.at(0).data() });
            }
        });
    }, [candidate, channelName]);

    const getChats = React.useCallback(() => {
        if (Object.keys(chat).length > 0) {
            const q = query(
                collection(db, "chat_messages"),
                where('parent', "==", chat.id),
                orderBy("createdAt", 'desc'),
                limit(1)
            );
            onSnapshot(q, async (QuerySnapshot) => {
                if (!QuerySnapshot.empty) {
                    setChatParentId(chat.id);
                }
            });
        }
    }, [chat]);

    React.useEffect(() => {
        // function to initialise the SDK
        let init = async (name) => {
            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType);
                if (mediaType === "video") {
                    setUsers((prevUsers) => {
                        return [...prevUsers, user];
                    });
                }
                if (mediaType === "audio") {
                    user.audioTrack?.stop();
                }
            });

            client.on("user-unpublished", (user, type) => {
                if (type === "audio") {
                    user.audioTrack?.stop();
                }
                if (type === "video") {
                    setUsers((prevUsers) => {
                        return prevUsers.filter((User) => User.uid !== user.uid);
                    });
                }
            });

            client.on("user-left", (user) => {
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            });

            let uid = await client.join(config.appId, name, config.token, null);
            if (tracks) await client.publish([tracks[0], tracks[1]]);
            if (uid) {
                setChatUser(uid);
            }
        };

        if (ready && tracks) {
            init(channelName);
        }
    }, [setChatUser, channelName, client, ready, tracks, config]);

    React.useEffect(() => {
        getChats()
    }, [getChats]);

    return (<React.Fragment>
        <div style={{ display: 'none' }}>
            {tracks && <Videos users={users} tracks={tracks} />}
        </div>
        {chatParentId !== '' && <div className='fixed bottom-0' style={{ marginBottom: 62 }}>
            <AssessorExamChat {...chat} onClose={() => setChatParentId('')} />
        </div>}
    </React.Fragment>
    );
};

export default CandidateProactor;

