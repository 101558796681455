import * as React from 'react';
import http from "../../utils/http";
import { Link, useParams } from 'react-router-dom';

const CandidateList = () => {
    const { id } = useParams();
    const [items, setItems] = React.useState([]);

    const getCandidates = React.useCallback(() => {
        http.get(`/api/v1/assessment-candidate/${id}`).then((res) => {
            setItems(res.data)
        });
    }, [id]);

    React.useEffect(() => {
        getCandidates()
    }, [getCandidates]);

    return <div>
        <div className="breadcrum">
            <div className="p-4 margin-b-20 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                <h2 className="assess-f">Candidate List</h2>
                <nav className="flex" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white dash-f">
                                <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                Dashboard
                            </Link>
                        </li>
                        <li aria-current="page">
                            <Link to="/today-assessment" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white dash-f">
                                <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                Today Assessment
                            </Link>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500 dash-f">Candidate List</span>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="strat-red">
                        <th scope="col" className="px-6 py-3">
                            Sr. No.
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Candidate ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Username
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span >Action</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items?.candidates?.length > 0 && items?.candidates?.map((ex, ei) => <tr key={ei} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{ei + 1}</th>
                        <td className="px-6 py-4">{ex.candidate_id}</td>
                        <td className="px-6 py-4">{ex?.username ?? '-'}</td>
                        <td className="px-6 py-4">{ex?.name ?? '-'}</td>
                        <td className="px-6 py-4 text-right lg:inline-block">
                            {items?.viva && <>{
                                ex.viva_count > 0 ?
                                    <span className='text-xs text-blue-600 font-medium viewattempt'>Viva Completed</span> :
                                    <Link to={`/viva/${items.assessment_id}/${ex._id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline viewattempt mr-2">Start Viva</Link>
                            } </>}
                            {items?.demo && <>{
                                ex.demo_count > 0 ?
                                    <span className='text-xs text-blue-600 font-medium viewattempt'>Practical Completed</span> :
                                    <Link to={`/demo/${items.assessment_id}/${ex._id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline viewattempt">Start Practical</Link>} </>}
                            {items?.ojt && <>{ex.ojt_count > 0 ?
                                <span className='text-xs text-blue-600 font-medium viewattempt'>OJT Completed</span> :
                                <Link to={`/ojt/${items.assessment_id}/${ex._id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline viewattempt">Start OJT</Link>}
                            </>}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>
};

export default CandidateList;