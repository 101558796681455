import React, { useState, useEffect } from 'react';
import './SpeedTyping.css';
import TypingArea from './TypingArea';
import http from "../../utils/http";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const TypingTester = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.auth.user);
    const [typingText, setTypingText] = useState('');
    // const [typingParagraphs, setTypingParagraphs] = useState('Loading...');
    const [inpFieldValue, setInpFieldValue] = useState('');
    const maxTime = 60;
    const [timeLeft, setTimeLeft] = useState(maxTime);
    const [charIndex, setCharIndex] = useState(0);
    const [mistakes, setMistakes] = useState(0);
    const [isTyping, setIsTyping] = useState(false);
    const [WPM, setWPM] = useState(0);
    const [CPM, setCPM] = useState(0);
    const [feedbackEnable, setFeedbackEnable] = React.useState(false);
    const [finalSts, setFinalSts] = React.useState(false);

    // const getExams = React.useCallback(() => {
    //     http.get(`/api/v1/exam-question/${id}/typing`).then((res) => {
    //         if (res.data.assessment?.feedback_enable) {
    //             setFeedbackEnable(true)
    //         }
    //         if (res.data.questions.length > 0 && res.data.questions[0].typingParagraph) {
    //             setTypingParagraphs(res.data.questions[0].typingParagraph)
    //         } else {
    //             navigate(`/exam/feedback/${id}`);
    //         }
    //     }).catch(error => {
    //         navigate(`/exam/feedback/${id}`);
    //     });
    // }, [id, navigate]);

    // React.useEffect(() => {
    //     getExams()
    // }, [getExams]);

    let typingParagraphs = [
        `Climate change is one of the most pressing issues of our time, with far-reaching implications for the planet and all its inhabitants. The Earth's climate has always undergone natural fluctuations, but the rapid changes observed in recent decades are largely attributed to human activities, particularly the burning of fossil fuels and deforestation. These activities release greenhouse gases such as carbon dioxide and methane into the atmosphere, trapping heat and leading to a rise in global temperatures. The consequences of climate change are wide-ranging and severe, including more frequent and intense natural disasters like hurricanes, droughts, and wildfires, as well as rising sea levels threatening coastal communities. Moreover, shifts in weather patterns impact agriculture, water resources, and biodiversity, exacerbating food insecurity and habitat loss. Addressing climate change requires collective action at local, national, and international levels, emphasizing renewable energy adoption, reforestation efforts, sustainable agriculture practices, and policy interventions to mitigate greenhouse gas emissions and adapt to changing environmental conditions.`,
        `Artificial intelligence (AI) has emerged as a transformative technology with profound implications for various aspects of society, from healthcare and transportation to finance and entertainment. AI refers to the development of computer systems capable of performing tasks that typically require human intelligence, such as problem-solving, pattern recognition, and decision-making. Machine learning, a subset of AI, enables computers to learn from data and improve performance over time without explicit programming. Applications of AI are vast and diverse, ranging from virtual assistants like Siri and Alexa to autonomous vehicles and medical diagnostics systems. While AI presents numerous opportunities for innovation and efficiency, it also raises ethical and societal concerns, including job displacement due to automation, data privacy breaches, algorithmic bias, and the potential for misuse in surveillance and warfare. Therefore, responsible AI development and governance frameworks are crucial to ensuring that AI technologies benefit humanity while minimizing risks and unintended consequences.`,
        `Globalization refers to the increasing interconnectedness and interdependence of economies, cultures, and societies worldwide, facilitated by advances in technology, transportation, and communication. This phenomenon has profoundly transformed the way people interact, conduct business, and exchange ideas across borders. Globalization has led to the expansion of international trade, investment, and outsourcing, creating new economic opportunities and driving economic growth in many parts of the world. Additionally, it has facilitated the spread of information, knowledge, and cultural exchange, contributing to greater cultural diversity and cross-cultural understanding. However, globalization also faces criticism for exacerbating income inequality, undermining local cultures and traditions, and perpetuating exploitation of labor and resources in developing countries. Moreover, global challenges such as climate change, pandemics, and terrorism highlight the interconnected nature of our world, necessitating coordinated global responses and cooperation among nations. As globalization continues to shape our interconnected world, it remains essential to address its benefits and challenges while striving for inclusive and sustainable development.`
    ]

    const loadParagraph = () => {
        const inputField = document.getElementsByClassName('input-field')[0];
        document.addEventListener("keydown", () => inputField.focus());
        const ranIndex = Math.floor(Math.random() * typingParagraphs.length);
        const content = Array.from(typingParagraphs[ranIndex]).map((letter, index) => (
            <span key={index} style={{ color: (letter !== ' ') ? 'black' : 'transparent' }}
                className={`char ${index === 0 ? 'active' : ''}`}>
                {(letter !== ' ') ? letter : '_'}
            </span>
        ));
        setTypingText(content);
        setInpFieldValue('');
        setCharIndex(0);
        setMistakes(0);
        setIsTyping(false);

        toast.success('The test begins when you press your first key');
    };
    const handleKeyDown = (event) => {
        const characters = document.querySelectorAll('.char');
        if ((event.key === 'Backspace' || event.key === 'Delete') && charIndex > 0 &&
            charIndex < characters.length && timeLeft > 0) {
            if (characters[charIndex - 1].classList.contains('correct')) {
                characters[charIndex - 1].classList.remove('correct');
            }
            if (characters[charIndex - 1].classList.contains('wrong')) {
                characters[charIndex - 1].classList.remove('wrong');
                setMistakes(mistakes - 1);
            }
            characters[charIndex].classList.remove('active');
            characters[charIndex - 1].classList.add('active');
            setCharIndex(charIndex - 1);
            let cpm = (charIndex - mistakes - 1) * (60 / (maxTime - timeLeft));
            cpm = cpm < 0 || !cpm || cpm === Infinity ? 0 : cpm;
            setCPM(parseInt(cpm, 10));
            let wpm = Math.round(((charIndex - mistakes) / 5) / (maxTime - timeLeft) * 60);
            wpm = wpm < 0 || !wpm || wpm === Infinity ? 0 : wpm;
            setWPM(wpm);
        }
    }

    const initTyping = (event) => {
        const characters = document.querySelectorAll('.char');
        let typedChar = event.target.value;
        if (charIndex < characters.length && timeLeft > 0) {
            let currentChar = characters[charIndex].innerText;
            if (currentChar === '_') currentChar = ' ';
            if (!isTyping) {
                setIsTyping(true);
            }
            if (typedChar === currentChar) {
                setCharIndex(charIndex + 1);
                if (charIndex + 1 < characters.length) characters[charIndex + 1].classList.add('active');
                characters[charIndex].classList.remove('active');
                characters[charIndex].classList.add('correct');
            } else {
                setCharIndex(charIndex + 1);
                setMistakes(mistakes + 1);
                characters[charIndex].classList.remove('active');
                if (charIndex + 1 < characters.length) characters[charIndex + 1].classList.add('active');
                characters[charIndex].classList.add('wrong');
            }

            if (charIndex === characters.length - 1) setIsTyping(false);

            let wpm = Math.round(((charIndex - mistakes) / 5) / (maxTime - timeLeft) * 60);
            wpm = wpm < 0 || !wpm || wpm === Infinity ? 0 : wpm;
            setWPM(wpm);

            let cpm = (charIndex - mistakes) * (60 / (maxTime - timeLeft));
            cpm = cpm < 0 || !cpm || cpm === Infinity ? 0 : cpm;
            setCPM(parseInt(cpm, 10));
        } else {
            setIsTyping(false);
        }
    };

    const resetResult = () => {
        setIsTyping(false);
        setTimeLeft(maxTime);
        setCharIndex(0);
        setMistakes(0);
        setTypingText('');
        setCPM(0);
        setWPM(0);
        const characters = document.querySelectorAll('.char');
        characters.forEach(span => {
            span.classList.remove("correct");
            span.classList.remove('wrong');
            span.classList.remove('active');
        });
        characters[0].classList.add('active');
        loadParagraph();
    };

    const submitResult = () => {
        let data = {
            typingResult: {
                mistake: mistakes,
                wpm: WPM,
                cpm: CPM,
                timeTake: maxTime - timeLeft
            }
        }
        http.post(`/api/v1/typing-result/${id}`, data).then((res) => {
            setFinalSts(true);
            setTimeout(() => {
                logout();
            }, 6000);
        }).catch(error => {
            console.error(error);
        })
    }

    useEffect(() => {
        loadParagraph();
    }, []);

    useEffect(() => {
        let interval;
        if (isTyping && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(timeLeft - 1);
                let cpm = (charIndex - mistakes) * (60 / (maxTime - timeLeft));
                cpm = cpm < 0 || !cpm || cpm === Infinity ? 0 : cpm;
                setCPM(parseInt(cpm, 10));
                let wpm = Math.round(((charIndex - mistakes) / 5) / (maxTime - timeLeft) * 60);
                wpm = wpm < 0 || !wpm || wpm === Infinity ? 0 : wpm;
                setWPM(wpm);
            }, 1000);
        } else if (timeLeft === 0) {
            clearInterval(interval);
            setIsTyping(false);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isTyping, timeLeft]);

    const logout = () => {
        navigate(`/exam/typing/pincode/${id}`);
    };

    return (
        <>
            {!finalSts &&
                <div className='flex'>
                    <div className="container">
                        <input
                            type="text"
                            className="input-field"
                            value={inpFieldValue}
                            onChange={initTyping}
                            onKeyDown={handleKeyDown}
                        />
                        <TypingArea
                            typingText={typingText}
                            inpFieldValue={inpFieldValue}
                            timeLeft={timeLeft}
                            mistakes={mistakes}
                            WPM={WPM}
                            CPM={CPM}
                            initTyping={initTyping}
                            handleKeyDown={handleKeyDown}
                            resetResult={resetResult}
                            submitResult={submitResult}
                        />
                    </div>
                </div>
            }
            {finalSts && <div className='h-screen flex items-center justify-center'>
                <div className='w-auto text-center'>
                    <div className='text-2xl text-green-600 font-bold'>Typing Test Submit Successfully.</div>
                    <div className='text-lg mt-2'>By {user?.name} ({user?.batch?.batch_id})</div>
                    <div className='text-lg mt-2'>Now you can click below to Pincode Test or autoredirect after 6 sec.</div>
                    <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Go To Pincode Test</button>
                </div>
            </div>}
        </>

    );
};

export default TypingTester; 
