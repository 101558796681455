import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import allQuestions from './question.json'
import http from "../../../utils/http";

const Exam = () => {
    const { id } = useParams();
    const user = useSelector(state => state.auth.user);
    const [questions, setQuestion] = React.useState([]);
    const [para, setPara] = React.useState('');
    const [active, setActive] = React.useState(0);
    const [finalSts, setFinalSts] = React.useState(false);

    React.useEffect(() => {
        const ranIndex = Math.floor(Math.random() * allQuestions.length);

        const getData = allQuestions[ranIndex];
        setQuestion(getData.questions);
        setPara(getData.passage);
    }, [])

    React.useEffect(() => {
        function openFullscreen() {
            var elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }
        document.documentElement.onclick = openFullscreen;
        document.onkeydown = openFullscreen;
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        return () => {
            document.documentElement.onclick = null;
            document.onkeydown = null;
            document.removeEventListener('contextmenu', null);
        };
    });

    if (questions.length === 0) {
        return <div className="flex h-screen w-screen items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    }

    const getLangQuestion = (index) => {
        if (questions.length === 0) return '';
        return questions[index].question;
    };

    const getLangOptionsData = (index) => {
        if (questions.length === 0) return [];
        return questions[index].options;
    };

    const counterClass = (index) => {
        if (questions.length === 0) return 'bg-black';
        if (index === active) return 'bg-blue';
        if (questions[index].answer) return 'bg-green';
        if (questions[index].review && questions[index].answer === null && index !== active) return 'bg-yellow !important';
        if (questions[index].visited && questions[index].answer === null && !questions[index].review) return 'bg-red !important';
        return 'bg-black';
    };

    const changeAnswer = (value, index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].answer = value;
        setQuestion(q);
    };

    const counterClck = (index, closeModel = false) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[active].visited = true;
        setQuestion(q);
        setActive(index);
    };

    const saveNext = (index) => {
        if (questions.length === 0 || questions.length - 1 === index) return false;
        counterClck(index + 1);
    };

    const savePrev = (index) => {
        if (questions.length === 0 || index === 0) return false;
        counterClck(index - 1);
    };

    const reviewLetter = (index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].review = true;
        q[index].answer = null;
        counterClck(index + 1);
        setQuestion(q);
    };

    const submitAnswer = () => {
        let data = {
            reading_exam: {
                questions,
                para
            }
        }
        http.post(`/api/v1/typing-result/${id}`, data).then((res) => {
            setFinalSts(true);
            setTimeout(() => {
                logout();
            }, 6000);
        }).catch(error => {
            console.error(error);
        })
    };

    const logout = () => {
        window.location.href = '/';
    };


    return <div>
        {!finalSts &&
            <div>
                <div className='grid lg:grid-cols-4 grid-cols-5 gap-4'>
                    <div className='col-span-3'>
                        <div style={{ whiteSpace: 'pre-line', margin: '0 2%', marginTop: '10px' }}>
                            {para}
                        </div>
                        <div className='question'>
                            <ol className='orderRules' type='1' start={active + 1}>
                                <li>
                                    <div>
                                        <div className='eng inline-block' dangerouslySetInnerHTML={{ __html: getLangQuestion(active) }}></div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <div className='options input-m'>
                            {getLangOptionsData(active).length > 0 && getLangOptionsData(active).map((opt, oi) => <div key={oi} className="flex items-start mb-6">
                                <div className="flex items-center h-5 lg:option-m">
                                    <input
                                        id={`terms-${opt}`}
                                        type="radio"
                                        name={`name-${active + 1}`}
                                        value={opt}
                                        onChange={(e) => changeAnswer(e.target.value, active)}
                                        checked={(questions.length > 0 && questions[active].answer === opt) ? true : false}
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                                    />
                                </div>
                                <label
                                    htmlFor={`terms-${opt}`} // Add corresponding ID of the input
                                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex flex-col"
                                >
                                    <div>
                                        <span className="eng-opt inline-block" dangerouslySetInnerHTML={{ __html: opt }}></span>
                                    </div>
                                </label>
                            </div>)}
                        </div>
                    </div>
                    <div className='lg:col-span-1 col-span-2'>
                        <div className="assess-de mb-[70px]">
                            <p>Questions Details</p>
                            <div className='overflow-y-auto' style={{ maxHeight: 'calc(100vh - 410px)' }}>
                                <div className='grid lg:grid-cols-7 grid-cols-4 lg:gap-2 gap-[3px] py-2 px-2'>
                                    {questions.length > 0 && questions.map((v, i) => <button key={i} className={`w-8 h-8 rounded-full text-white ${counterClass(i)}`} onClick={() => counterClck(i)}>{i + 1}</button>)}
                                </div>
                            </div>
                            <div className="assess-bott contents">
                                <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-yellow"></span>Review Later</div>
                                <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-red"></span>UnAnswered</div>
                                <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-black"></span>Not Visited</div>
                                <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-blue"></span>Active</div>
                                <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-green"></span>Answered</div>
                            </div>
                        </div>
                    </div>
                    <div className='footerbottom lg:pl-50px col-span-4'>
                        <button type="button" className="text-white w-1/4 lg:w-auto cursor-pointer bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2" disabled={active === 0 ? true : false} onClick={() => savePrev(active)}>Previous</button>
                        {((questions.length - 1) !== active) && <button type="button" className={`text-white w-[31%] lg:w-auto cursor-pointer bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2`} disabled={((questions.length - 1) === active) ? true : false} onClick={() => saveNext(active)}>Save &amp; Next</button>}
                        {((questions.length - 1) !== active) && <button type="button" className="text-white cursor-pointer w-[31%] lg:w-auto bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2" disabled={(questions.length > 0 && questions[active].answer === '') ? true : ''} onClick={() => reviewLetter(active)}>Review Later</button>}
                        {((questions.length - 1) === active) && <button type="button" className="text-white w-1/4 cursor-pointer lg:w-auto bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat" onClick={() => submitAnswer()}>Submit</button>}
                    </div>
                </div>
            </div>
        }
        {finalSts && <div className='h-screen flex items-center justify-center'>
            <div className='w-auto text-center'>
                <div className='text-2xl text-green-600 font-bold'>Reading Comprehension Test Submit Successfully.</div>
                <div className='text-lg mt-2'>By {user?.name} ({user?.batch?.batch_id})</div>
                <div className='text-lg mt-2'>Now you can click below to logout or auto redirect after 6 sec.</div>
                <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Go To Home</button>
            </div>
        </div>}
    </div>;
};

export default Exam;