// TypingArea.js 
import React from 'react';

const TypingArea = ({
    typingText,
    inpFieldValue,
    timeLeft,
    mistakes,
    WPM,
    CPM,
    initTyping,
    handleKeyDown,
    resetResult,
    submitResult
}) => {
    return (
        <div className="section">
            <div className="section1">
                <p id="paragraph">{typingText}</p>
            </div>
            <div className="section2">
                <ul className="resultDetails">
                    <li className="time">
                        <p>Time Left:</p>
                        <span>
                            <b>{timeLeft}</b>s
                        </span>
                    </li>
                    <li className="mistake">
                        <p>Mistakes:</p>
                        <span>{mistakes}</span>
                    </li>
                    <li className="wpm">
                        <p>WPM:</p>
                        <span>{WPM}</span>
                    </li>
                    <li className="cpm">
                        <p>CPM:</p>
                        <span>{CPM}</span>
                    </li>
                </ul>
                <div style={{ display: "flex" }}>
                    {/* <button onClick={resetResult} className="btn">
                        Try Again
                    </button> */}
                    <button onClick={submitResult} className="btn">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TypingArea; 
