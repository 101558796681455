import * as React from 'react';
import { useParams } from 'react-router-dom';
import http from "../../utils/http";
import moment from 'moment';

const Attendance = () => {
    const { id } = useParams();
    const [items, setItems] = React.useState([]);

    const getCandidates = React.useCallback(() => {
        http.get(`/api/v1/batch-candidate/${id}`).then((res) => setItems(res.data));
    }, [id]);

    React.useEffect(() => {
        getCandidates()
    }, [getCandidates]);

    return <div className='lg:border-2 lg:border-black m-2 p-1 inline-block' style={{ width: "-webkit-fill-available" }} >
        <table className='w-full text-xs border-separate text-center' cellPadding={0} cellSpacing={0}>
            <tbody>
                <tr>
                    <td className='border border-black w-1/2' >Batch ID</td>
                    <td className='border border-black' rowSpan={4}>
                        <div className='flex justify-center'>
                            <img className='h-44 w-44 object-contain' alt='ssc logo' src={items?.sector?.logo || '/logo.png'} />
                            {process.env.REACT_APP_TITLE != "Clarion Skills" && <img className='h-44 w-44 ml-[10%] object-contain' alt='ssc logo' src='/cleverattiskills/logo.png' />}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className='border border-black'>{items?.batch_id}</td>
                </tr>
                <tr>
                    <td className='border border-black'>Docket No.</td>
                </tr>
                <tr>
                    <td className='border border-black'>-</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div className='uppercase font-semibold bg-amber-600 p-2 my-1 mx-auto'>{items?.sector?.name}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table className='w-full text-left' cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>VTP Name :</td><td className='border border-black text-center'>{items?.vtp_name}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Center Name / ID:</td><td className='border border-black text-center'>{items?.center_name}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Job Role:</td><td className='border border-black text-center'>{items?.jobrole?.name}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Job Role Code:</td><td className='border border-black text-center'>{items?.jobrole?.code}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Level:</td><td className='border border-black text-center'>{items?.jobrole?.level}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>SPOC Name:</td><td className='border border-black text-center'>{items?.poc_name}</td>
                                </tr>
                                {(items?.sector?.short_name == 'LSSSDC' || items?.sector?.short_name == 'MEPSC') && <tr>
                                    <td className='border border-black bg-blue-400 p-1'>TR ID:</td><td className='border border-black text-center'></td>
                                </tr>}
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>SPOC Contact:</td><td className='border border-black text-center'>{items?.poc_number}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table className='w-full text-left' cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr><td colSpan={2} className='border border-black bg-blue-400 p-1 text-center'>ADDRESS</td></tr>
                                <tr><td colSpan={2} className='h-12 border border-black text-center'>{items?.center_address}</td></tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>State :</td><td className='border border-black text-center'>{items?.state?.name}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Centre City/District:</td><td className='border border-black text-center'>{items?.city?.name}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Date of Assessment:</td><td className='border border-black text-center'>{moment(items?.assessment_date).format('DD-MMM-YYYY')}</td>
                                </tr>
                                {(items?.sector?.short_name == 'LSSSDC' || items?.sector?.short_name == 'MEPSC') && <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Trainer name:</td><td className='border border-black text-center'></td>
                                </tr>}
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Assessor Name:</td><td className='border border-black text-center'>{items?.assessor?.name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="py-1">
                        <table className='w-full text-left' cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr className='text-white bg-indigo-600 text-center'>
                                    <th className='border border-black'>S.n</th>
                                    <th className='border border-black'>Candidate Name</th>
                                    <th className='border border-black'>Centre Cand ID</th>
                                    <th className='border border-black'>Adhaar Number</th>
                                    <th className='border border-black'>Fathers/Guardian</th>
                                    <th className='border border-black'>Mobile</th>
                                    <th className='border border-black'>Gender</th>
                                    <th className='border border-black'>Education</th>
                                    <th className='border border-black'>Signature</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items?.users && items?.users?.map((u, ui) => <tr key={ui} className="text-left">
                                    <td className='border border-black p-1'>{ui + 1}</td>
                                    <td className='border border-black p-1'>{u.name}</td>
                                    <td className='border border-black p-1'>{u.candidate_id}</td>
                                    <td className='border border-black p-1'></td>
                                    <td className='border border-black p-1'></td>
                                    <td className='border border-black p-1'></td>
                                    <td className='border border-black p-1'></td>
                                    <td className='border border-black p-1'></td>
                                    <td className='border border-black p-1'></td>
                                </tr>)}
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr className='bg-blue-400'>
                    <td className='border border-black p-1'>Center Supervisor</td>
                    <td className='border border-black p-1'>Assessor</td>
                </tr>
                <tr>
                    <td>
                        <table className='w-full text-left' cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>SPOC Name :</td><td className='border border-black text-center'>{items?.poc_name || '-'}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>SPOC Contact No.:</td><td className='border border-black text-center'>{items?.poc_number || '-'}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Signature & Stamp:</td><td className='border border-black text-center'></td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table className='w-full text-left' cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Assessor Name :</td><td className='border border-black text-center'>{items?.jobrole?.assessor?.name}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Assessor Contact No.:</td><td className='border border-black text-center'>{items?.jobrole?.assessor?.mobile}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black bg-blue-400 p-1'>Signature :</td><td className='border border-black text-center'></td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>;
};
export default Attendance;