import * as React from 'react';
import {
    AgoraVideoPlayer,
    createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import { query, where, collection, onSnapshot, limit, getDocs } from "firebase/firestore";
import { db } from "../../utils/firestore";
import http from "../../utils/http";
import AssessorExamChat from './AssessorExamChat';

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const Videos = ({ users, tracks, config, uid }) => {
    const [start, setStart] = React.useState(false);
    const [chat, setChat] = React.useState({});
    const [chatMap, setChatMap] = React.useState({});
    const [recording, setRecording] = React.useState({ sid: '', resourceId: '' });
    const startRecording = () => {
        http.post(`/api/v1/agora/start-recording`, { channel: config.channelName, token: config.token, uid: 0 }).then((res) => {
            setRecording(res.data.data);
            setStart(true);
        });
    };
    const stopRecording = () => {
        http.post(`/api/v1/agora/stop-recording`, { channel: config.channelName, sid: recording.sid, resourceId: recording.resourceId }).then((res) => {
            setStart(false);
        });
    };

    const startChat = React.useCallback((uid) => {
        const q = query(
            collection(db, "chat_users"),
            where('uid', "==", uid),
            where('channel', "==", config.channelName),
            limit(1)
        );
        onSnapshot(q, async (QuerySnapshot) => {
            if (!QuerySnapshot.empty) {
                setChat({ id: QuerySnapshot.docs.at(0).id, uid, user: QuerySnapshot.docs.at(0).data() });
            }
        });
    }, [config]);

    const fetchCandidateNames = React.useCallback(async () => {
        const uids = users.map((user) => user.uid);
        const q = query(
            collection(db, "chat_users"),
            where('uid', 'in', uids),
            where('channel', '==', config.channelName)
        );
        const querySnapshot = await getDocs(q);
        const chats = {};
        querySnapshot.forEach((doc) => {
            const uid = doc.data().uid;
            const userData = doc.data();
            chats[uid] = { id: doc.id, uid, user: userData };
        });
        setChatMap(chats);
    }, [config.channelName, users])

    React.useEffect(() => {
        if (users.length > 0) {
            fetchCandidateNames();
        }
    }, [chatMap, fetchCandidateNames, users]);

    const renderCandidateName = (user) => {
        if (chatMap[user.uid]) {
            const candidateName = chatMap[user.uid].user.name;
            return <div>Candidate Name: {candidateName}</div>;
        }
        return null;
    };

    return (
        <div className='relative'>
            <div className='grid gap-2 grid-cols-2'>
                <p>Candidates: {users.length}</p>
                {start ? <button onClick={stopRecording} className="font-medium text-red-600 dark:text-red-500">Stop Recording</button> :
                    <button onClick={startRecording} className="font-medium text-blue-600 dark:text-blue-500">Start Recording</button>
                }
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                <div className="w-full h-80 border hidden">
                    <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} style={{ height: 'inherit', width: 'inherit' }} />
                </div>
                {users.length > 0 &&
                    users.map((user) => {
                        if (user.videoTrack) {
                            return (<div className="w-full h-80 border relative" key={user.uid} onClick={() => startChat(user.uid)}>
                                <AgoraVideoPlayer videoTrack={user.videoTrack} style={{ height: 'inherit', width: 'inherit' }} key={user.uid} />
                                <div>{renderCandidateName(user)}</div>
                            </div>);
                        } else return null;
                    })}
            </div>
            {Object.keys(chat).length > 0 && <div className='fixed bottom-0'>
                <AssessorExamChat {...chat} onClose={() => setChat({})} />
            </div>}
        </div>
    );
};

const AssessorProactor = ({ channelName, client, config }) => {
    const [users, setUsers] = React.useState([]);
    const [start, setStart] = React.useState(false);
    const [uid, setUid] = React.useState('');
    const { ready, tracks } = useMicrophoneAndCameraTracks();

    React.useEffect(() => {
        // function to initialise the SDK
        let init = async (name) => {
            client.on("user-published", async (user, mediaType) => {
                console.info('user-published', user);
                await client.subscribe(user, mediaType);
                if (mediaType === "video") {
                    setUsers((prevUsers) => {
                        return [...prevUsers, user];
                    });
                }
                if (mediaType === "audio") {
                    user.audioTrack?.play();
                }
            });

            client.on("user-unpublished", (user, type) => {
                if (type === "audio") {
                    user.audioTrack?.stop();
                }
                if (type === "video") {
                    setUsers((prevUsers) => {
                        return prevUsers.filter((User) => User.uid !== user.uid);
                    });
                }
            });

            client.on("user-left", (user) => {
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            });

            let uid = await client.join(config.appId, name, config.token, null);
            if (tracks) await client.publish([tracks[0], tracks[1]]);
            if (tracks) await tracks[0].setEnabled(false);
            if (uid) {
                setUid(uid);
            }
            setStart(true);
        };

        if (ready && tracks) {
            init(channelName);
        }
    }, [channelName, client, ready, tracks, config]);

    return (
        <div>
            {start && tracks && <Videos users={users} tracks={tracks} config={{ ...config, channelName: channelName }} uid={uid} />}
        </div>
    );
};

export default AssessorProactor;