import * as React from 'react';

import {
    createClient,
} from "agora-rtc-react";
import AssessorProactor from '../../components/Agora/AssessorProactor';
import http from "../../utils/http";
import { useParams } from 'react-router-dom';
// import { useSelector } from 'react-redux';
let config = {
    mode: "rtc", codec: "vp8", appId: process.env.REACT_APP_AGORA_APP_ID
};
const useClient = createClient(config);

const LiveExam = () => {
    const { id } = useParams();
    // const user = useSelector(state => state.auth.user);
    const client = useClient();
    const [loading, setLoading] = React.useState(true);

    const getToken = React.useCallback(() => {
        http.post(`/api/v1/agora/rtc/${id}/publisher/uid/0`).then((res) => {
            config['token'] = res.data.rtcToken;
            setLoading(false);
        });
    }, [id]);

    React.useEffect(() => {
        getToken();
    }, [getToken]);

    if(loading){
        return <div>Loading</div>;
    }

    return <React.Fragment>
        <div className='relative'>
            <AssessorProactor config={config} client={client} channelName={id}/>
        </div>
    </React.Fragment>
};

export default LiveExam;