import { useSelector } from "react-redux";

const Dashboard = () => {
    const user = useSelector(state => state.auth.user);
    return <div className="p-4 margin-b-20 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 dash-he">
        <div className="welcome-dash">
            <h2>WELCOME : {user.name}</h2>
        </div>
    </div>
};

export default Dashboard;