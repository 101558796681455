import { useCallback, useEffect, useRef, useState } from "react";
import http from "../utils/http";
const AudioRecorder = (id) => {
    const recorder = useRef(null);
    const mediaStream = useRef(null);
    const [chunks, setChunks] = useState([]);
    const startAudioRecording = () => {
        navigator.mediaDevices.getUserMedia({
            audio:true,
        }).then((stream) => {
            mediaStream.current = stream;
            recorder.current = new MediaRecorder(stream);

            let interval;
            recorder.current.onstart = () => {
                interval = setInterval(() => {
                    recorder.current.requestData();
                }, 10000);
            };

            recorder.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setChunks((prevChunks) => [...prevChunks, event.data]);
                }
            };

            recorder.current.onstop = () => {
                clearInterval(interval);
            };

            recorder.current.start();
        });
    };

    const saveAudio = useCallback((blob) => {
        const formData = new FormData();
        formData.append('data', blob, 'audio.webm');
        http.post(`/api/v1/s3-stream/${id}-audio`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => console.log(response.data))
            .catch((error) => console.error(error));
    }, [id]);

    useEffect(() => {
        if (chunks.length > 0) {
            const blob = new Blob(chunks, { type: "audio/webm" });
            saveAudio(blob);
            setChunks([]);
        }
    }, [chunks, saveAudio]);

    const stopAudioRecording = () => {
        if (recorder.current && recorder.current.state === "recording") {
            recorder.current.stop();
        }
        if (mediaStream.current) {
            mediaStream.current.getTracks().forEach(track => track.stop());
        }
    };

    return { startAudioRecording, stopAudioRecording, recorder };
};

export default AudioRecorder;