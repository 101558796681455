import * as React from 'react';
import http from "../../utils/http";
import { useNavigate, useParams } from 'react-router-dom';

const CandidateOJT = () => {
    const navigate = useNavigate();
    const { assessment_id, candidate_id } = useParams();
    const [questions, setQuestions] = React.useState([]);
    const [active, setActive] = React.useState(0);
    const [candidate, setCandidate] = React.useState({});
    const [finalSts, setFinalSts] = React.useState(false);

    const getDemoData = React.useCallback(() => {
        http.get(`/api/v1/ojt/${assessment_id}/${candidate_id}`).then((res) => {
            setQuestions(res.data.questions);
            setCandidate(res.data.user);
        });
    }, [assessment_id, candidate_id]);

    React.useEffect(() => {
        getDemoData()
    }, [getDemoData]);

    if (questions.length === 0) {
        return <div className="flex h-screen w-screen items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    }

    const counterClass = (index) => {
        if (questions.length === 0) return 'bg-black';
        if (index === active) return 'bg-blue';
        else if (questions[index].marks) return 'bg-green';
        else if (questions[index].review) return 'bg-yellow';
        else if (questions[index].visited) return 'bg-red';
        else return 'bg-black';
    };

    const counterClck = (index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[active].visited = true;
        setQuestions(q);
        setActive(index);
    };

    const changeAnswer = (value, index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].marks = value;
        setQuestions(q);
    };

    const saveNext = (index, final = false) => {
        if (questions.length === 0) return false;
        let q = [...questions];
        var post = { nos: q[index].nos._id, marks: q[index].marks };
        if (final) {
            post['final_submit'] = true;
            post['user_data'] = { mode: "web" };
        }
        http.post(`/api/v1/ojt-marks/${assessment_id}/${candidate_id}`, post).then((res) => {
            if (final) {
                setFinalSts(true);
                setTimeout(() => {
                    logout();
                }, 6000);
            } else {
                counterClck(index + 1);
            }
        }).catch(error => {

        })
    };

    const logout = () => {
        navigate(`/candidate/${assessment_id}`, { replace: true });
    };

    return <div className='h-screen w-screen'>
        {!finalSts && <div className='h-full grid grid-cols-8 gap-2'>
            <div className='col-span-6'>
                <div className='w-full h-full grid grid-cols-7 gap-2'>
                    <div className='col-span-7'>
                        <div>
                            <ol type='1' start={active + 1} className="orderRules">
                                <li>
                                    {questions[active].nos.name}
                                </li>
                            </ol>
                        </div>
                        <div className="margin-lt-35">
                            <div className="relative z-0 w-full mb-6 group">
                                <label htmlFor="language" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Marks</label>
                                <select id='language' value={questions[active].marks ? questions[active].marks : ""} onChange={(e) => changeAnswer(e.target.value, active)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full-50">
                                    <option value="">Select Marks</option>
                                    {questions[active]?.total_marks && [...Array(Number(questions[active]?.total_marks))].map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-2 hidden'>
                        <div className="assess-de h-2/5">
                            <p>Candidate</p>
                            <div className="can-he">
                            </div>
                        </div>
                        <div className="assess-de h-2/5">
                            <p>Assessor</p>
                            <div className="can-he">
                                <span className="session"><b>Session ID:-</b> 1653743072</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-span-2'>
                <div className="assess-de">
                    <p>OJT Detail</p>
                    <ol className="assess-list">
                        <span className="assess-span"><span className="span-float"><b>Candidate Name:</b></span><li>{candidate?.name}</li></span>
                        <span className="assess-span"><span className="span-float"><b>SSC:</b></span><li>{candidate?.sector?.name}</li></span>
                        <span className="assess-span"><span className="span-float"><b>QP:</b></span><li>{candidate?.jobrole?.name}</li></span>
                    </ol>
                </div>

                <div className="assess-de">
                    <p>Questions</p>
                    <div className='overflow-y-auto' style={{ maxHeight: 'calc(100vh - 380px)' }}>
                        <div className='grid grid-cols-7 gap-2 py-2 px-2'>
                            {questions.length > 0 && questions.map((v, i) => <button key={i} className={`w-8 h-8 rounded-full text-white ${counterClass(i)}`} onClick={() => counterClck(i)}>{i + 1}</button>)}
                        </div>
                    </div>
                    <div className="assess-bott">
                        <div className="rev-com"><span className="common-cls bg-yellow"></span>Review Later</div>
                        <div className="rev-com"><span className="common-cls bg-red"></span>UnAnswered</div>
                        <div className="rev-com not-vis"><span className="common-cls bg-black"></span>Not Visited</div>
                        <div className="rev-com"><span className="common-cls bg-blue"></span>Active</div>
                        <div className="rev-com"><span className="common-cls bg-green"></span>Answered</div>
                    </div>
                </div>
            </div>
            <div className="footerbottom col-span-7">
                <button type="button" disabled={(questions.length - 1) === active} onClick={() => saveNext(active)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rev-lat savne">Save & Next</button>
                {((questions.length - 1) === active) && <button type="button" onClick={() => saveNext(active, true)} className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rev-lat">Submit</button>}
                {/* <p className="all-the">'All the online practical sessions are recorded for future references and audit purposes'</p> */}
            </div>
        </div>}
        {finalSts && <div className='h-screen flex items-center justify-center'>
            <div className='w-auto text-center'>
                <div className='text-2xl text-green-600 font-bold'>OJT Submit Successfully.</div>
                <div className='text-lg mt-2'>Now you can click below to button or autoredirect after 6 sec.</div>
                <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Go To Candidate List</button>
            </div>
        </div>}
    </div>
};

export default CandidateOJT;