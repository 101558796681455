import { useEffect, useState } from "react";
import Dialog from "./Dialog";
import { toast } from 'react-toastify';

const CaptchaAlert = (props) => {
    const [image, setImage] = useState('');
    const [code, setCode] = useState('');
    const [enterCode, setEnterCode] = useState('');
    const [msg, setMsg] = useState('');
    const generateCaptcha = () => {
        var charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lengthOtp = 6;
        var captcha = [];
        for (var i = 0; i < lengthOtp; i++) {
            var index = Math.floor(Math.random() * charsArray.length + 1);
            if (captcha.indexOf(charsArray[index]) === -1)
                captcha.push(charsArray[index]);
            else i--;
        }
        var canv = document.createElement("canvas");
        canv.width = 100;
        canv.height = 50;
        var ctx = canv.getContext("2d");
        ctx.font = "25px Georgia";
        ctx.strokeText(captcha.join(""), 0, 30);
        setCode(captcha.join(""));
        setImage(canv.toDataURL("image/png"));
    };
    useEffect(() => {
        if (image === '') {
            generateCaptcha();
        }
    }, [image]);
    const verifyCaptcha = (e) => {
        e.preventDefault();
        setMsg('');
        if (code === enterCode) {
            props.onClose(false);
            // toast.warn(`This is your ${props.violation} warning out of ${props.violation_count}`);
        } else {
            setMsg('Incorrect entered captcha!');
        }
        setImage('');
        setEnterCode('');
    };
    return <Dialog active={props.active}>
        <div className="mt-3 text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">Verify Captcha</h3>
            <div className="mx-auto flex items-center justify-around">
                <img src={image} width="100" height="50" alt="captcha" className="border p-2" />
                <button onClick={() => setImage('')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z" /></svg>
                </button>
            </div>
            <div className="mt-2 px-7 py-3">
                <form className="w-full max-w-sm" onSubmit={verifyCaptcha}>
                    <div className="flex items-center border-b border-gray-400 py-2">
                        <input value={enterCode} onChange={(e) => setEnterCode(e.target.value)} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Enter captcha here..." aria-label="Captcha code" />
                        <button className="flex-shrink-0 bg-gray-600 hover:bg-gray-900 text-sm text-white py-1 px-2 rounded" type="submit">Verify</button>
                    </div>
                </form>
                {msg && <p className="text-center text-red-600">{msg}</p>}
            </div>
        </div>
    </Dialog>
};

export default CaptchaAlert;