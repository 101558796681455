// TypingArea.js 
import React from 'react';

const TypingArea = ({
    typingText,
    inpFieldValue,
    timeLeft,
    mistakes,
    KPH,
    GKPH,
    accuracy,
    correction,
    totalKey,
    initTyping,
    handleKeyDown,
    getResult,
    getResultStatus,
    submitResult
}) => {
    return (
        <div className="section">
            <div className="section1">
                <p id="paragraph" className='h-[200px] overflow-hidden font-bold'>{typingText}</p>
            </div>
            <textarea
                className='mt-4 resize-none bg-[#eeeeee] border-[#6b7280] p-2'
                placeholder='The test begins when you press your first key'
                value={inpFieldValue}
                onChange={initTyping}
                onKeyDown={handleKeyDown}
                onPaste={(e) => e.preventDefault()}
                autoComplete="off"
                name="content"
                id="content"
                cols="50"
                rows="5">
            </textarea>
            {getResultStatus ?
                <>
                    <div className="section2">
                        <ul className="resultDetails">
                            <li className="time">
                                <p>Time Left:</p>
                                <span>
                                    <b>{timeLeft}</b>s
                                </span>
                            </li>
                            {/* <li className="mistake">
                                <p>Mistakes:</p>
                                <span>{mistakes}</span>
                            </li> */}
                            <li className="KPH">
                                <p>KPH:</p>
                                <span>{KPH}</span>
                            </li>
                            <li className="GKPH">
                                <p>Gross KPH:</p>
                                <span>{GKPH}</span>
                            </li>
                            <li className="accuracy">
                                <p>Accuracy:</p>
                                <span>{(accuracy == NaN || accuracy == 'NaN') ? '0.00' : accuracy}%</span>
                            </li>
                            {/* <li className="correction">
                                <p>Correction:</p>
                                <span>{correction}</span>
                            </li> */}
                            <li className="totalKey">
                                <p>Total KeyStrokes:</p>
                                <span>{totalKey}</span>
                            </li>
                        </ul>
                    </div>
                    <button onClick={submitResult} className="btn">
                        Submit
                    </button>
                </>
                :
                <div className='flex'>
                    <div className="time mr-5">
                        <p>Time Left:</p>
                        <span>
                            <b>{timeLeft}</b>s
                        </span>
                    </div>
                    <button className="btn ml-5" onClick={getResult}>
                        Get Result
                    </button>
                </div>
            }
        </div>
    );
};

export default TypingArea; 
